import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CommonService } from '../shared/services/common-service/common.service';
import { catchError, mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class ServiceInterceptor implements HttpInterceptor {

  constructor(private commonService: CommonService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token: string = "";
    if (this.commonService.user.getValue()) {
      token = this.commonService.user.getValue().jwt;
    }
    
    if (token) { // adding token for every request after login
      request = request.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + token,
        }
      });
    }
    this.commonService.loader.next(true);
    return this.sendRequest(request, next);
  }

  /**
   * send request
   * @param request
   * @response object
   */
  sendRequest(request: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(request).pipe(
      mergeMap(async (res) => res),
      tap((res) => {
        if (res instanceof HttpResponse) {
          this.commonService.loader.next(false);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.commonService.loader.next(false);
        if (error.error.statusCode === 401) {
          this.commonService.logout();
        }
        let errorMessage: string = error.error.message ? error.error.message : "";
        console.log("error", error.error.message);
        if (error.error.message && error.error.message.length > 0 && typeof error.error.message !== "string") {
          errorMessage = error.error?.message[0]?.messages[0]?.message;
        }
        this.commonService.showError(errorMessage, "Error");
        return throwError(() => error.error.message);
      })
    );
  }
}
