import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, fromEvent, map, timer } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment.development';
import { HttpClient } from '@angular/common/http';
import { API_NAME } from '../../constants/api-name';
import { OTHERS } from '../../constants/others';



@Injectable({
  providedIn: 'root'
})
export class CommonService {

  loader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  user: BehaviorSubject<any> = new BehaviorSubject({});
  userName: BehaviorSubject<string> = new BehaviorSubject("");
  S3Url: string = environment.S3_URL;

  constructor(private toasterService: ToastrService, private cookieService: CookieService, private router: Router, private http: HttpClient) {
    if (this.getItemInCookie("user")) {
      this.user.next(JSON.parse(this.getItemInCookie("user")));
    }
    if (this.getItemInCookie("username")) {
      this.userName.next(this.getItemInCookie("username"));
    }
  }

  /**
   * show success
   * @param message 
   * @param title 
   * @param config 
   */
  showSuccess(message: string, title?: string, config?: Partial<IndividualConfig<any>>) {
    this.toasterService.success(message, title, config ? config : {});
  }

  /**
   * show info
   * @param message 
   * @param title 
   * @param config 
   */
  showInfo(message: string, title?: string, config?: Partial<IndividualConfig<any>>) {
    this.toasterService.info(message, title, config ? config : {});
  }

  /**
   * show error
   * @param message 
   * @param title 
   * @param config 
   */
  showError(message: string, title?: string, config?: Partial<IndividualConfig<any>>) {
    this.toasterService.error(message, title, config ? config : {});
  }

  /**
   * show warning
   * @param message 
   * @param title 
   * @param config 
   */
  showWarning(message: string, title?: string, config?: Partial<IndividualConfig<any>>) {
    this.toasterService.warning(message, title, config ? config : {});
  }

  /**
   * logout
   * @param null
   * @response null
   */
  logout() {
    this.cookieService.delete("user", "/");
    this.router.navigate(["/sessions/login"]);
  }

  /**
   * trim spaces
   * @param inputArray
   * @response promise
   */
  trimArray(inputArray: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let returnObj: any = {};
      Object.keys(inputArray).map((data, i) => {
        if (typeof inputArray[data] === "string" && inputArray[data] !== "password" && inputArray[data] !== "newPassword" && inputArray[data] !== "confirmPassword") {
          returnObj[data] = inputArray[data].trim();
        }
        else {
          returnObj[data] = inputArray[data];
        }
      });
      resolve(returnObj);
    });
  }

  /**
    * set user
    * @param user
    * @response null
    */
  setUser(user: any) {
    this.user.next(null);
    this.setItemInCookie("user", JSON.stringify(user));
    this.user.next(JSON.parse(JSON.stringify(user)));
    timer(500).subscribe(() => this.setItemInCookie("username", this.user.getValue().user?.fullname));
    this.userName.next(this.user.getValue().user?.fullname);
    console.log("users", this.user.getValue());
  }

  /**
   * set item in cookie
   * @param key
   * @param value
   * @returns null
   */
  setItemInCookie(key: string, value: string) {
    const salt = environment.AES_SALT;
    let cryptValue = CryptoJS.AES.encrypt(value, salt).toString();
    this.cookieService.set(key, cryptValue, 999999, "/");
  }

  /**
   * get item in cookie
   * @param key
   * @returns null
   */
  getItemInCookie(key: string) {
    const salt = environment.AES_SALT;
    let cryptedValue = this.cookieService.get(key);
    if (cryptedValue) {
      let bytes = CryptoJS.AES.decrypt(cryptedValue, salt);
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return "";
  }

  /**
   * remove item in cookie
   * @param key
   * @returns null
   */
  removeItemInCookie(key: string) {
    this.cookieService.delete(key, "/")
  }

  /**
   * type debounce
   * @param elementRef
   * @response observable
   */
  typeDebounce(elementRef: any, time: number = 500): Observable<any> {
    return fromEvent(elementRef, "keyup").pipe(
      map((event: any) => {
        return event.target.value
      }),
      debounceTime(time),
      distinctUntilChanged()
    );
  }

  /**
   * check user tiktok login
   * @param null
   * @response null
   */
  checkUserTiktokLogin(): Observable<any> {
    return this.http.get(environment.CONTENT_API_URL + API_NAME.tiktokLoginCheck);
  }

  /**
   * convert bytes to size
   * @param bytes
   * @param decimals
   * @response null
   */
  convertBytesToSize(bytes: number, decimals: number = 2) {
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
