import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

export const sessionGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const cookieService = inject(CookieService)

  const token = cookieService.get("user");
  if (token) {
    // logged in so return true
    router.navigate(['/']);
    return false;
  } else {
    return true;
  }
};
