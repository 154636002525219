export const API_NAME = {
    auth: "auth/",
    contents: "userContents",
    users: "users/",
    sessions: "sessions/",
    tiktokLogin: "tiktokAppLogin",
    tiktokCallback: "tiktokAppCallback",
    instagramLogin: "instagramLogin",
    instagramCallback: "instagramCallback",
    tiktokLoginCheck: "userVerifyTiktokToken",
    uploadingContents: "uploadingContents",
    directUploads: "uploadUserVideos",
    updateProfilePic: "profile/image",
    updateUserSocialAccounts: "profile/socialLinks",
    strapiContent: "strapiContents",
    checkContents: "checkContents",
    unpublishcontent: "unpublishContent",
}