import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth.guard';
import { sessionGuard } from './shared/guards/session.guard';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'home/dashboard',
		pathMatch: 'full'
	},
	{
		path: 'sessions',
		loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
		canActivate: [sessionGuard]
	},
	{
		path: 'home',
		loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
		canActivate: [authGuard],
	},
	{
		path: 'upload-content',
		loadChildren: () => import('./views/upload-content/upload-content.module').then(m => m.UploadContentModule),
		canActivate: [authGuard],
	},
	{
		path: 'pending-approval',
		loadChildren: () => import('./views/pending-approval/pending-approval.module').then(m => m.PendingApprovalModule),
		canActivate: [authGuard],
	},
	{
		path: 'analytics',
		loadChildren: () => import('./views/analytics/analytics.module').then(m => m.AnalyticsModule),
		canActivate: [authGuard],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
