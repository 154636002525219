import { Component, inject } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common-service/common.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  loader: boolean = false;
  
  constructor(private commonService: CommonService) {
    this.commonService.loader.subscribe(data => {
      this.loader = data;
    });
  }
}
